<script>
  import screen from '@/helpers/screen'
  import accountApi from '@/api/account'

  export default {
    data() {
      return {
        sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
        selectedSize: null,
        images: [
          '/static/subscribe/tshirt-back.png',
          '/static/subscribe/tshirt-front.png',
          '/static/subscribe/tshirt-detail.png',
        ],
        backorderedSizes: [],
        isSubmitting: false,
        hasError: false,
        showSuccessModal: false,
        upgradeSuccessfulTimeout: null,
        tshirtDetails:
          'Made exclusively for our Alltrue+ community, this 100% organic cotton tee features an illustration by Australian artist Antra Švarcs that celebrates women, nature, and thoughtfully made goods. Ooze optimism whenever you wear it.',
      }
    },
    computed: {
      screen,
      selectedSizeLabel() {
        switch (this.selectedSize) {
          case 'XS':
            return 'Extra Small'
          case 'S':
            return 'Small'
          case 'M':
            return 'Medium'
          case 'L':
            return 'Large'
          case 'XL':
            return 'Extra Large'
          case 'XXL':
            return 'Extra Extra Large'
          case 'XXXL':
            return 'Extra Extra Extra Large'
          default:
            return ''
        }
      },
      selectedSizeIsBackordered() {
        return this.backorderedSizes.includes(this.selectedSize)
      },
    },
    watch: {
      showSuccessModal() {
        clearTimeout(this.upgradeSuccessfulTimeout)
        if (this.showSuccessModal) {
          this.upgradeSuccessfulTimeout = setTimeout(() => {
            this.$navigate({
              name: 'AccountProfileSubscriptions',
            })
            this.showSuccessModal = false
          }, 3000)
        }
      },
    },
    methods: {
      submitSize() {
        // key must match Surveys.php
        return accountApi.setSurveyAnswer({
          key: 'tshirt-size',
          value: this.selectedSize,
        })
      },
      async navigateToNextPage() {
        this.isSubmitting = true
        try {
          const response = await this.submitSize()
          if (response.success) {
            if (this.$route.name === 'AccountSubscribeTshirt') {
              this.showSuccessModal = true
            } else {
              this.$navigate({
                name: 'SubscribeBonuses',
                params: {
                  ...this.$route.params,
                },
              })
            }
          } else {
            this.hasError = true
          }
        } catch (error) {
          if (error) this.hasError = true
        } finally {
          this.isSubmitting = false
        }
      },
    },
  }
</script>

<template>
  <div>
    <div v-if="$route.name === 'AccountSubscribeTshirt'" class="text-center mt-6 text-xs">
      <span>Select a size for your Alltrue+ T-shirt. </span>
      <BaseDivider color="dawn-dk2" class="my-6 max-w-140 lg:max-w-245 mx-auto" />
    </div>
    <div class="mt-6 mb-14 flex flex-col lg:flex-row justify-center max-w-140 lg:max-w-245 mx-auto">
      <!-- Mobile header -->
      <div v-if="!screen.lg" class="text-center mb-6">
        <span class="border border-black font-bold text-2xs rounded leading-close px-2 py-3px">
          EXCLUSIVE
        </span>
        <h1
          class="mt-2 font-heading font-semibold text-4xl leading-none text-center max-w-90 lg:max-w-none mx-auto"
        >
          Members Only Alltrue+ T-shirt
        </h1>
      </div>
      <!-- Image gallery -->
      <BaseSliderWithGallery
        :images="images"
        alt="tshirt"
        has-navigation
        :gallery-on-left="screen.lg"
        arrow-bg-color="none"
        class="shirt-slider lg:flex-1"
      />
      <div class="flex flex-col lg:flex-1 lg:w-full lg:max-w-97 px-2 lg:px-0 lg:ml-4">
        <!-- Desktop header and description -->
        <template v-if="screen.lg">
          <div>
            <span class="border border-black font-bold text-2xs rounded leading-close px-2 py-3px">
              EXCLUSIVE
            </span>
            <h1 class="mt-2 font-heading font-semibold text-4xl leading-none">
              Members Only Alltrue+ <br />
              T-shirt
            </h1>
          </div>
          <p class="mt-9px mb-10px text-xs leading-close">
            {{ tshirtDetails }}
          </p>
        </template>
        <BaseDivider v-if="!screen.lg" color="black" class="mt-1 mb-2 w-full" />
        <!-- Size selector -->
        <div class="font-semibold text-2xs leading-close">SIZE</div>
        <div class="flex justify-between mt-13px mb-4">
          <button
            v-for="(size, sizeIndex) in sizes"
            :key="sizeIndex"
            :class="`w-45px h-45px flex items-center justify-center font-bold text-sm leading-close border-2 border-black ${
              selectedSize === size ? 'bg-black text-dawn' : ''
            }`"
            @click="selectedSize = size"
          >
            {{ size }}
          </button>
        </div>
        <!-- Backordered copy -->
        <div v-if="selectedSizeIsBackordered" class="leading-snug-tight mb-4 text-2xs">
          <span class="uppercase font-bold">BACKORDERED - SIZE {{ selectedSizeLabel }}</span>
          <p class="font-medium">
            Expected to be in stock: Apr 20 - Apr 24. We will notify you as soon as it’s ready to
            ship.
          </p>
        </div>
        <BaseDivider v-if="!screen.lg" color="black" class="mb-4 w-full" />
        <!-- 'Select size' text that turns into a button once size is selected -->
        <div
          :class="`border border-black font-bold text-lg py-4 text-center ${
            selectedSize ? 'hidden' : 'visible'
          }`"
        >
          SELECT A SIZE
        </div>
        <BaseButton
          color="black"
          icon-position="right"
          :icon-size="3"
          :class="`${selectedSize ? 'visible' : 'hidden'}`"
          @click="navigateToNextPage"
        >
          CONTINUE
          <template v-slot:icon>
            <IconChevronRightThick />
          </template>
        </BaseButton>
        <!-- Mobile description -->
        <div v-if="!screen.lg" class="mt-8">
          <span class="font-bold text-2xs leading-close">DETAILS</span>
          <p class="mt-4 text-xs leading-close">
            {{ tshirtDetails }}
          </p>
        </div>
        <div class="mt-4" />
        <!-- Extra details in accordions -->
        <div class="border-t border-b border-black">
          <BaseAccordion button-inner-classes="py-4">
            <template v-slot:buttonLabel>
              <span class="font-bold">FIT &amp; CARE</span>
            </template>
            <div class="mb-4 text-xs leading-close">
              This fair-trade certified T-shirt is made with preshrunk organic cotton and comes in
              standard unisex sizing. Machine wash.
            </div>
          </BaseAccordion>
        </div>
      </div>
      <BaseSpinner v-if="isSubmitting" overlay="fixed" overlay-color="white" class="z-10" />
      <!-- General error handling -->
      <Portal to="modal">
        <BaseModalError :open="hasError" icon-color="primary-dark" @dismiss="hasError = false">
          Please try again and contact us at
          <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled>
          if the problem persists.
        </BaseModalError>
        <BaseModal
          :open="showSuccessModal"
          size="md"
          bg-color="dawn"
          class="flex flex-col items-center text-center"
        >
          <div class="py-12">
            <h2 class="font-heading font-medium text-4xl leading-none"> We're on it!</h2>
            <div class="mt-3 mb-6"> Your T-shirt will be sent with your next box. </div>
            <BaseImage
              src="/static/illustrations/IllustrationAnnualUpsell.png"
              alt=""
              background-transparent
              class="max-w-66 m-auto"
            />
          </div>
        </BaseModal>
      </Portal>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  /* Override BaseSlider's default CSS */
  .shirt-slider {
    & >>> {
      & > .slider {
        .swiper-button-prev,
        .swiper-button-next {
          @apply border-none;

          @screen lg {
            @apply top-0 mt-46;
          }
        }
      }
    }
  }
</style>
